import React, { useReducer, useEffect } from "react"
import { graphql, Link } from "gatsby"
import Algolia from "algoliasearch"
import styled from "styled-components"

import media from "../styles/media"
import { ChevronLeft } from "../components/icons"
import { GridContainer, Layout } from "../components/common"
import * as RosterGrid from "../components/roster/Grid"
import { useHeaderContext } from "../components/context/HeaderContext"
import { isNotPlaceholder } from "../utils/common/content"
import SEO from "../components/common/SEO"

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

const BackButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  &:hover {
    #Back {
      stroke: ${props => props.theme.red};
    }
  }
`

const ResultsSection = styled.section`
  grid-column: 1 / -1;

  h3 {
    color: ${props => props.theme.lightGrey3};
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 30px;
    text-transform: uppercase;
  }
`

const Query = styled.h2`
  align-items: center;
  display: flex;
  font-family: StanleyRegular, serif;
  font-size: 45px;
  font-weight: normal;
  grid-column: 1 / -1;
  letter-spacing: -0.27px;
  line-height: 30px;

  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 250ms ease-in-out;
  transition-delay: ${props => (props.show ? "250ms" : 0)};
  svg {
    margin-right: 22px;
  }

  ${media.tablet`
    font-size: 45px;
    letter-spacing: 0;
    line-height: 52px;

    svg {
      margin-right: 42px; 
    }
  `}
`

// a fix for orphaned elements at the end of the flex grid for ie11
// it fills the row with blanks so the last items do not try and
// fill the space themselves and become huge
const flexFix = sortedArtists => {
  const arr = []

  for (let i = 0; i < 6 - (sortedArtists.length % 6); i++) {
    arr.push(<RosterGrid.GridBox aria-hidden="true" />)
  }
  return arr
}

const algoliaClient = Algolia(
  process.env.GATSBY_ALGOLIA_APPLICATION_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
)

const contentfulContentIndex = algoliaClient.initIndex("contentful_content")

const searchActions = {
  SET_RESULTS: "SET_RESULTS",
  SET_ERROR: "SET_ERROR",
}

const initialState = {
  isLoading: true,
  error: null,
  results: [],
}

function searchReducer(state, action) {
  switch (action.type) {
    case searchActions.SET_RESULTS:
      return {
        ...state,
        isLoading: false,
        error: null,
        results: action.payload,
      }
    case searchActions.SET_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        results: [],
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

function Search({ data, location }) {
  const allArtists = data.allContentfulArtist.edges.map(({ node }) => node)
  const allNewsArticles = data.allContentfulNewsArticle.edges.map(
    ({ node }) => node
  )

  const [{ isLoading, error, results }, dispatch] = useReducer(
    searchReducer,
    initialState
  )

  let query

  if (location.state && location.state.query) {
    query = location.state.query
  } else if (location.search) {
    const params = new URLSearchParams(location.search)
    const paramQuery = params.get("q")

    if (paramQuery) {
      query = paramQuery
    }
  }

  useEffect(() => {
    contentfulContentIndex
      .search({ query })
      .then(({ hits }) =>
        dispatch({ type: searchActions.SET_RESULTS, payload: hits })
      )
      .catch(error => {
        dispatch({ type: searchActions.SET_ERROR, payload: error })
      })
  }, [query])

  const { showSearch, setShowSearch } = useHeaderContext()

  const artistResults = results.filter(
    ({ contentType }) => contentType === "artist"
  )

  const newsResults = results.filter(
    ({ contentType }) => contentType === "news"
  )

  return (
    <Layout headerTheme="dark">
      <Wrapper>
        <SEO title="Columbia Artists - Search" path="/search" />

        <GridContainer>
          <Query show={!showSearch}>
            <BackButton onClick={() => setShowSearch(!showSearch)}>
              <ChevronLeft />
            </BackButton>
            {query}
          </Query>

          {error && <span>An error occurred. Please try again</span>}

          {!isLoading && !error && (
            <>
              {artistResults.length !== 0 && (
                <ResultsSection>
                  <h3>Artists</h3>
                  <RosterGrid.GridBoxContainer>
                    {artistResults
                      .filter(a => isNotPlaceholder({ node: a }))
                      .map(artist => {
                        const artistRecord = allArtists.find(
                          x => x.contentful_id === artist.objectID
                        )

                        if (!artistRecord) {
                          return null
                        }

                        const {
                          slug,
                          thumbnailImage,
                          categories,
                        } = artistRecord

                        return (
                          <RosterGrid.GridBox>
                            <Link to={`/artists/${slug}`}>
                              {thumbnailImage && (
                                <RosterGrid.GridImage
                                  alt={artist.name}
                                  fluid={thumbnailImage.fluid}
                                />
                              )}

                              <div>
                                <RosterGrid.GridContent>
                                  <RosterGrid.GridTitleStyle>
                                    {artist.name}{" "}
                                    <span>{categories[0].name}</span>
                                  </RosterGrid.GridTitleStyle>
                                </RosterGrid.GridContent>
                              </div>
                            </Link>
                          </RosterGrid.GridBox>
                        )
                      })}
                    {flexFix(
                      artistResults
                        .filter(a => isNotPlaceholder({ node: a }))
                        .filter(artist => {
                          const artistRecord = allArtists.find(
                            x => x.contentful_id === artist.objectID
                          )

                          return artistRecord
                        })
                    )}
                  </RosterGrid.GridBoxContainer>
                </ResultsSection>
              )}

              {newsResults.length !== 0 && (
                <ResultsSection>
                  <h3>News</h3>
                  <RosterGrid.GridBoxContainer>
                    {results
                      .filter(a => isNotPlaceholder({ node: a }))
                      .filter(({ contentType }) => contentType === "news")
                      .map(article => {
                        const articleRecord = allNewsArticles.find(
                          x => x.contentful_id === article.objectID
                        )

                        if (!articleRecord) {
                          return null
                        }

                        const { slug, coverImage } = articleRecord

                        return (
                          <RosterGrid.GridBox>
                            <Link to={`/news/${slug}`}>
                              <RosterGrid.GridImage
                                alt={article.title}
                                fluid={coverImage.fluid}
                              />

                              <div>
                                <RosterGrid.GridContent>
                                  <RosterGrid.GridTitleStyle>
                                    {article.title}
                                  </RosterGrid.GridTitleStyle>
                                </RosterGrid.GridContent>
                              </div>
                            </Link>
                          </RosterGrid.GridBox>
                        )
                      })}

                    {flexFix(
                      results
                        .filter(a => isNotPlaceholder({ node: a }))
                        .filter(({ contentType }) => contentType === "news")
                        .filter(article => {
                          const articleRecord = allNewsArticles.find(
                            x => x.contentful_id === article.objectID
                          )

                          return articleRecord
                        })
                    )}
                  </RosterGrid.GridBoxContainer>
                </ResultsSection>
              )}
            </>
          )}
        </GridContainer>
      </Wrapper>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    allContentfulArtist {
      edges {
        node {
          contentful_id
          slug
          thumbnailImage {
            fluid(maxWidth: 400, maxHeight: 400, quality: 80) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          categories {
            name
            keepPlural
          }
        }
      }
    }
    allContentfulNewsArticle {
      edges {
        node {
          contentful_id
          slug
          coverImage {
            fluid(maxWidth: 400, maxHeight: 400, quality: 60) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

export default Search
