import Img from "gatsby-image"
import styled from "styled-components"

import media from "../../styles/media"

const GridBoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;

  @supports (display: grid) {
    min-width: 100%;
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    ${media.tablet`
      grid-column: 1 /span 12;
      grid-template-columns: repeat(6, 1fr);
    `}
  }

  p {
    margin: 0 auto;
  }

  div {
    margin-top: 16px;
  }

  h4,
  p {
    display: inline-block;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.red};
    }
  }
`
const GridBox = styled.div`
  padding: 0 10px 20px 10px;

  @supports (display: grid) {
    width: 100%;
    height: 100%;
  }

  div {
    margin-top: 5px;
  }

  flex: 1 1 45%;
  justify-content: flex-start;

  ${media.tablet`
    flex: 1 1 15%;  
  `}
`

const GridImage = styled(Img)`
  font-family: "object-fit: cover; object-position: center center;"; //ie polyfill
`

const GridContent = styled.div`
  display: inline;
`

const GridTitleStyle = styled.h4`
  margin: 0 auto;
  padding: 0 auto;
  font-size: 13px;
  font-weight: bold;

  span {
    font-weight: normal;
  }

  ${media.tablet`
    font-size: 16px;
  `}
`

export { GridBoxContainer, GridBox, GridImage, GridContent, GridTitleStyle }
